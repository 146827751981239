<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <v-flex xs12>
        <v-tabs grow background-color="#f2f2f2" color="black" v-model="tab">
          <v-tab class="black--text fonte-bold"
            >Diretos ({{ getUnilevels.level_1.length }})</v-tab
          >
          <v-tab class="black--text fonte-bold"
            >Nivel 2 ({{ getUnilevels.level_2.length }})</v-tab
          >
          <v-tab class="black--text fonte-bold"
            >Nivel 3 ({{ getUnilevels.level_3.length }})</v-tab
          >
          <v-tab class="black--text fonte-bold"
            >Nivel 4 ({{ getUnilevels.level_4.length }})</v-tab
          >
        </v-tabs>
      </v-flex>

      <v-flex v-if="tab === 0" xs12 class="pa-1">
        <v-simple-table
          v-show="getUnilevels.level_1.length > 0"
          dense
          style="width: 100%;"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  NOME
                </th>
                <th class="fonte">TELEFONE</th>
                <th class="fonte">INDICADO POR</th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  ATIVO
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  DATA DE INGRESSO
                </th>
                <th
                  class="fonte-bold black--text"
                  style="font-size: 12pt;"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getUnilevels.level_1" :key="item._id">
                <td style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_name }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_phone }}
                  <v-btn small icon @click="openWhats(item.indicated_phone)">
                    <v-icon color="green">mdi-whatsapp</v-icon>
                  </v-btn>
                </td>
                <td class="font-weight-bold fonte">
                  indicado por mim
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.active ? "sim" : "não" }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </td>
                <td class="font-weight-bold fonte">
                  <div
                    v-if="!item.active"
                    class="expande-horizontal centraliza column"
                  >
                    <v-btn
                      outlined
                      x-small
                      v-if="!confirm"
                      @click="openRemoveConnection(item)"
                      >Remover</v-btn
                    >
                    <v-btn
                      x-small
                      class="mt-1"
                      color="green"
                      outlined
                      v-if="confirm && confirm == item._id"
                      @click="removeConnection(item)"
                      >Confirmar ?</v-btn
                    >
                    <v-btn
                      class="ma-1 text-none"
                      text
                      x-small
                      v-if="confirm && confirm == item._id"
                      @click="cancelRemoveConnection"
                      >Cancelar</v-btn
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-list class="pa-0" color="transparent" v-else>
          <template v-for="(item, index) in getUnilevels.level_1">
            <v-list-item :key="item._id">
              <v-avatar>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="fonte-bold">
                    {{ item.indicated_name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  indicado por mim
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.active ? "Ativo" : "Inativo" }}, entrou em
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.indicated_phone }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="fonte">
                  <div v-if="!item.active" class="expande-horizontal">
                    <v-btn
                      outlined
                      x-small
                      v-if="!confirm"
                      @click="openRemoveConnection(item)"
                      >Remover</v-btn
                    >
                    <v-btn
                      x-small
                      class="mt-1"
                      color="green"
                      outlined
                      v-if="confirm && (confirm == item._id)"
                      @click="removeConnection(item)"
                      >Confirmar ?</v-btn
                    >
                    <v-btn
                      class="ma-1 text-none"
                      text
                      x-small
                      v-if="confirm && (confirm == item._id)"
                      @click="cancelRemoveConnection"
                      >Cancelar</v-btn
                    >
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small icon @click="openWhats(item.indicated_phone)">
                  <v-icon color="green">mdi-whatsapp</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
        <Empty v-if="getUnilevels.level_1.length === 0"></Empty>
      </v-flex>

      <v-flex v-if="tab === 1" xs12 class="pa-1">
        <v-simple-table
          v-show="getUnilevels.level_2.length > 0"
          dense
          style="width: 100%;"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  NOME
                </th>
                <th class="fonte">TELEFONE</th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  INDICADO POR
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  ATIVO
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  DATA DE INGRESSO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getUnilevels.level_2" :key="item._id">
                <td style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_name }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_phone }}
                  <v-btn small icon @click="openWhats(item.indicated_phone)">
                    <v-icon color="green">mdi-whatsapp</v-icon>
                  </v-btn>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.current_sponsor_name }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.active ? "sim" : "não" }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-list class="pa-0" color="transparent" v-else>
          <template v-for="(item, index) in getUnilevels.level_2">
            <v-list-item :key="item._id">
              <v-avatar>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="fonte-bold">
                    {{ item.indicated_name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  indicado por {{ item.current_sponsor_name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.active ? "Ativo" : "Inativo" }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.indicated_phone }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="fonte">
                  Desde
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small icon @click="openWhats(item.indicated_phone)">
                  <v-icon color="green">mdi-whatsapp</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
        <Empty v-if="getUnilevels.level_2.length === 0"></Empty>
      </v-flex>

      <v-flex v-if="tab === 2" xs12 class="pa-1">
        <v-simple-table
          v-show="getUnilevels.level_3.length > 0"
          dense
          style="width: 100%;"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  NOME
                </th>
                <th class="fonte">TELEFONE</th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  INDICADO POR
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  ATIVO
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  DATA DE INGRESSO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getUnilevels.level_3" :key="item._id">
                <td style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_name }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_phone }}
                  <v-btn small icon @click="openWhats(item.indicated_phone)">
                    <v-icon color="green">mdi-whatsapp</v-icon>
                  </v-btn>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.current_sponsor_name }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.active ? "sim" : "não" }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-list class="pa-0" color="transparent" v-else>
          <template v-for="(item, index) in getUnilevels.level_3">
            <v-list-item :key="item._id">
              <v-avatar>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="fonte-bold">
                    {{ item.indicated_name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  indicado por {{ item.current_sponsor_name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.active ? "Ativo" : "Inativo" }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.indicated_phone }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="fonte">
                  Desde
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small icon @click="openWhats(item.indicated_phone)">
                  <v-icon color="green">mdi-whatsapp</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
        <Empty v-if="getUnilevels.level_1.length === 0"></Empty>
      </v-flex>

      <v-flex v-if="tab === 3" xs12 class="pa-1">
        <v-simple-table
          v-show="getUnilevels.level_4.length > 0"
          dense
          style="width: 100%;"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  NOME
                </th>
                <!-- <th class="fonte">TELEFONE</th> -->
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  LOGIN
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  ATIVO
                </th>
                <th class="fonte-bold black--text" style="font-size: 12pt;">
                  DATA DE INGRESSO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getUnilevels.level_4" :key="item._id">
                <td style="width: 20px;">
                  <v-icon color="#333">mdi-account-group-outline</v-icon>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_name }}
                </td>
                <!-- <td class="font-weight-bold fonte">{{ item.indicated_phone }}</td> -->
                <td class="font-weight-bold fonte">
                  {{ item.indicated_login }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.active ? "sim" : "não" }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-list class="pa-0" color="transparent" v-else>
          <template v-for="(item, index) in getUnilevels.level_4">
            <v-list-item :key="item._id">
              <v-avatar>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="fonte-bold">
                    {{ item.indicated_name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  indicado por {{ item.current_sponsor_name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.active ? "Ativo" : "Inativo" }}
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle> {{ item.indicated_phone }} </v-list-item-subtitle> -->
                <v-list-item-subtitle class="fonte">
                  Desde
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
        <Empty v-if="getUnilevels.level_4.length === 0"></Empty>
      </v-flex>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      modalViewShortBinary: false,
      modalBinaryContent: {
        unilevel: {
          level_1: []
        }
      },
      confirm: false,
      tab: 0
    };
  },
  computed: {
    ...mapGetters(["getUnilevels"])
  },
  methods: {
    ...mapActions(["listUnilevels"]),
    openWhats(phone) {
      const phoneFormatted = phone.replace(/[^0-9]/g, "");
      window.open(`https://api.whatsapp.com/send?phone=55${phoneFormatted}`);
    },
    openRemoveConnection(item) {
      this.confirm = item._id;
    },
    cancelRemoveConnection() {
      this.confirm = false;
    },
    removeConnection(item) {
      if (!item) return;
      this.$store.dispatch("enableLoading");
      this.modalViewShortBinary = false;

      this.$run("/unilevel/remove-unilevel", { _id: item.indicated_id }, "user")
        .then(res => {
          this.modalBinaryContent = {
            unilevel: []
          };
          this.modalViewShortBinary = false;
          this.confirm = false;
          this.listUnilevels();
          alert("Membro da rede removido com sucesso!");
          this.$store.dispatch("disableLoading");
        })
        .catch(() => {
          this.$store.dispatch("disableLoading");
          alert("Esta pessoa não faz parte da sua rede");
        });
    },
    openItemBinary(item) {
      this.$store.dispatch("enableLoading");
      this.modalViewShortBinary = false;

      this.$run(
        "/unilevel/view-user-unilevel",
        { _id: item.indicated_id },
        "user"
      )
        .then(res => {
          this.modalBinaryContent = {
            ...item,
            unilevel: res.data
          };
          this.modalViewShortBinary = true;
          this.$store.dispatch("disableLoading");
        })
        .catch(() => {
          this.$store.dispatch("disableLoading");
          alert("Erro ao carregar a lista, tente novamente");
        });
    }
  },
  created() {
    this.listUnilevels();
  }
};
</script>

<style scoped>
.level {
  position: relative;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: center; */
}
.octagon {
  /* width: 200px;
    height: 200px; */
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1a4f70;
  flex-direction: column;
  z-index: 999;
  border-radius: 50%;
  background: #e9e9e9;
  /* background: linear-gradient(45deg, #1a4f70, #216892, #3b88b5, #216892, #1a4f70); */
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  /* clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  ) !important; */
}
.octagon:hover {
  transform: scale(1.1);
  transition: 0.4s;
  z-index: 999;
  clip-path: circle(50%) !important;
}
</style>
